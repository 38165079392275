function makeRoleMenu(data) {
  let navMenu = [];
  let role = {};
  data.forEach(value => {
    // 하위메뉴가 없을 때 => resource가 null이 아님
    if (value.childMenus.length === 0) {
      navMenu.push({
        _tag: 'CSidebarNavItem',
        name: value.menuName,
        route: value.menuName,
        to: value.resource.frontUri,
      });
      role[value.resource.frontUri] = {
        'name': value.menuName,
        "readAuth": value.readAuth,
        "writeAuth": value.writeAuth,
        "excelAuth": value.excelAuth,
        "id": value.id
      };
    }
    // 하위메뉴가 있을 때
    else {
      const _children = [];
      value.childMenus.forEach(child => {
        const navMenu = {
          _tag: 'CSidebarNavItem',
          name: child.menuName,
          to: child.resource.frontUri,
        };
        _children.push(navMenu);
        role[child.resource.frontUri] = {
          'name': child.menuName,
          "readAuth": child.readAuth,
          "writeAuth": child.writeAuth,
          "excelAuth": child.excelAuth,
          "id": value.id
        };
      });
      navMenu.push({
        _tag: 'CSidebarNavDropdown',
        name: value.menuName,
        route: value.menuName,
        _children
      });

    }
  });
  return [navMenu, role];
}

export default makeRoleMenu;
