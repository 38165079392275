import React from "react";
import { AppProvider } from "../context/AppContext";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// prettier-ignore
const ResetPw = React.lazy(() => import("../views/login/resetPassword/EmailResetPassword"));
const TheLayout = React.lazy(() => import("../component/containers/TheLayout"));
const Login = React.lazy(() => import("../views/login/Login"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const Router = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/login/reset"
              name="Login Page"
              render={(props) => <ResetPw {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </AppProvider>
    </BrowserRouter>
  );
};

export default Router;
