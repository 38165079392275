/**
 * 로컬모드 여부
 * @returns {boolean}
 */
export function isLocalMode() {
  return process.env.NODE_ENV === "local";
}

/**
 * 개발모드 여부
 * @returns {boolean}
 */
export function isDevMode() {
  return process.env.NODE_ENV === "development";
}

/**
 * 운영모드 여부
 * @returns {boolean}
 */
export function isLiveMode() {
  return process.env.NODE_ENV === "production";
}

export function isDevServer() {
  return window.location.hostname === "dev.partner.tuneit.io";
}

export function isLiveServer() {
  return window.location.hostname === "partner.tuneit.io";
}
