export const detectMobileBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return true;
  }
  if (/tablet/i.test(userAgent)) {
    return true;
  }
  return false;
};
