import React, { useState } from "react";
import { createEventBusHelper } from "../utils/EventBusHelper";
import EventTypes from "../lib/EventTypes";
import { useHistory, useLocation } from "react-router-dom";
import * as CookieUtil from "../utils/CookieUtil";
import { reset } from "../utils/CookieUtil";
import * as Apis from "../apis/Apis";
import * as ApiErrorHandler from "../apis/ApiErrorHandler";
import { CSpinner } from "@coreui/react";
import DialogManager from "../component/dialog/DialogManager";
import makeRoleMenu from "../function/makeRoleMenu.js";
import { detectMobileBrowser } from "../utils/detectMobileBrowser";
import { setFirebaseUserProperties } from "../lib/firebase.lib";

const EventBusHelper = createEventBusHelper();
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [user, setUser] = React.useState();
  const [authToken, setAuthToken] = React.useState(
    CookieUtil.getAuthorization(),
  );
  const [roles, setRoles] = React.useState([]);
  const [comTypeCd, setComTypeCd] = useState([]);
  const [nav, setNav] = useState([]);
  const [roleObj, setRoleObj] = useState({});

  const [resetPwToken, setResetPwToken] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const setAuthTokenWrapper = (data) => {
    CookieUtil.setAuthorization(data);
    setAuthToken(data);
  };

  const store = {
    setLoading,
    user,
    setUser,
    authToken,
    setAuthToken: setAuthTokenWrapper,
    roles,
    comTypeCd,
    setComTypeCd,
    roleObj,
    setRoleObj,
    nav,
    setNav,
    resetPwToken,
    isMobile,
  };

  // 마스터에서 파트너 로그인
  React.useEffect(async () => {
    const urlSearch = new URLSearchParams(location.search);
    const adminToken = urlSearch.get("admintoken");
    if (adminToken) {
      reset("Authorization");
      setAuthTokenWrapper(adminToken);
      const result = await Apis.getRoleMenuAfterTokenLogin(adminToken);
      const [navMenu, role] = makeRoleMenu(result.data);
      setRoleObj({ ...role });
      setNav([...navMenu]);
      setRoles(result.data);
    } else {
      return;
    }
  }, []);

  // 접속 시 토큰 유무 파악 (리다이렉트)
  React.useEffect(async () => {
    checkUserAgent();
    if (authToken) {
      try {
        await LoginByTokenFetch();
      } catch (e) {
        ApiErrorHandler.all(e);
        history.replace("/login");
      } finally {
        setLoaded(true);
      }
    } else {
      setLoaded(true);
      const urlSearch = new URLSearchParams(location.search);
      const urlToken = urlSearch.get("token");
      // 비밀번호 재설정 메일로 접근하는 경우
      if (urlToken) {
        setResetPwToken(urlToken);
      } else {
        // 토큰 없을 때 /login으로 이동
        history.replace("/login");
      }
    }
    EventBusHelper.addListener(EventTypes.App.Logout, () => {
      setAuthTokenWrapper(null);
      history.replace("/login");
    });
    return () => {
      EventBusHelper.removeAllListeners();
    };
  }, []);

  const checkUserAgent = () => {
    setIsMobile(detectMobileBrowser());
  };

  const LoginByTokenFetch = async () => {
    try {
      const { data } = await Apis.loginByToken();
      const { adminId, adminNo, userName, authorization, comCd } = data;
      setFirebaseUserProperties(comCd, adminNo, userName);
      setUser({ adminId, adminNo, userName });
      setAuthTokenWrapper(authorization);

      const result = await Apis.getRoleMenuAfterTokenLogin(authorization);
      const [navMenu, role] = makeRoleMenu(result.data);
      setRoleObj({ ...role });
      setNav([...navMenu]);
      setRoles(result.data);
    } catch (e) {
      ApiErrorHandler.all(e);
    }
  };

  const renderLoading = () => (
    <div className={"renderLoading"}>
      <CSpinner />
    </div>
  );

  return (
    <AppContext.Provider value={store}>
      {loaded && children}
      <DialogManager />
      {loading && renderLoading()}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
