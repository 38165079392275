import * as EventBus from "../utils/EventBus";
import * as ModelUtils from "../utils/ModelUtils";
import EventTypes from "../lib/EventTypes";
import { tt } from "../utils/LangUtil";
import NewModalPage from "../component/NewModalPage.js";
import DialogManager from "../component/dialog/DialogManager.js";
import React from "react";

export function auth(error) {
  if (error.status === 401) {
    EventBus.publish(EventTypes.App.Logout);
    return true;
  }
  return false;
}

export function all(error) {
  let message = error.message;
  switch (error.status) {
    case 400:
      break;
    case 401:
      message = tt("다시 로그인해주세요.");
      EventBus.publish(EventTypes.App.Logout);
      break;
    default:
      if (ModelUtils.isLiveMode()) {
        message = tt(
          "일시적인 오류가 발생하였습니다. 잠시후 다시 시도해 주세요."
        );
      }
      break;
  }
  alert(message);
}

export function allCode(error) {
  let message = error.message;
  let onClose;
  switch (error.status) {
    case 400:
      break;
    case 401:
      message = tt("다시 로그인해주세요.");
      EventBus.publish(EventTypes.App.Logout);
      break;
    case 403:
      break;
    default:
      if (ModelUtils.isLiveMode()) {
        message = tt(
          "일시적인 오류가 발생하였습니다. 잠시후 다시 시도해 주세요."
        );
      }
      break;
  }
  message = message.split("\n").map((str, index, arr) => {
    return (
      <span key={`err-message-${index}`}>
        {str}
        {index !== arr.length - 1 && <br />}
      </span>
    );
  });
  DialogManager.push(
    <NewModalPage message={message} right={tt("확인")} onClose={onClose} />
  );
}

export function ignore(error) {
  console.error(error);
}
