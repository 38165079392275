import firebase from "firebase/app";
import "firebase/analytics";
import { isDevMode, isDevServer } from "../utils/ModelUtils";

const devFirebaseConfig = {
  apiKey: "AIzaSyDDoumDBnERACnsJjobPxONkhOvkYjxZJE",
  authDomain: "tuneit-admin-test.firebaseapp.com",
  projectId: "tuneit-admin-test",
  storageBucket: "tuneit-admin-test.appspot.com",
  messagingSenderId: "960482909586",
  appId: "1:960482909586:web:6f1b9b70b2afd7f5f2b54b",
  measurementId: "G-H98LVPMKXF",
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyBTvEt1YjLkrubz9P61LLWBIaHrt-n31lg",
  authDomain: "tuneit-admin.firebaseapp.com",
  projectId: "tuneit-admin",
  storageBucket: "tuneit-admin.appspot.com",
  messagingSenderId: "247398530458",
  appId: "1:247398530458:web:06ff171975231dce288e23",
  measurementId: "G-M7NG2C5KGW",
};

if (isDevServer() || isDevMode()) {
  // 개발모드
  firebase.initializeApp(devFirebaseConfig);
  console.log("dev");
} else {
  // 운영모드
  firebase.initializeApp(prodFirebaseConfig);
  console.log("prod");
}

export const setFirebaseUserProperties = (
  comName: string,
  adminNo: number,
  adminName: string,
) => {
  firebase.analytics().setUserProperties({
    comName,
    adminNo,
    adminName,
  });
};
// firebase
//   .analytics()
//   .setUserProperties({ tuneit_admin_master: "tuneit_partner_test" });
// {
//   adminNo : 22,
//     adminName : '함장수',
//   comName : "tuneit"
// }

export const analyticsLogEvent = (key: string, params?: {}) => {
  firebase.analytics().logEvent(key, params);
};
