import ApiRequest, { requestByTempToken } from "./ApiRequest";
import { getAuthorization } from "../utils/CookieUtil";
import qs from "qs";

export const Api = new ApiRequest(process.env.REACT_APP_API_URL);

/**
 * 그룹코드 API 를 호출하는 함수
 * @param type {string}
 * @param code {string} 해당 옵션이 없는 경우 null 입력
 * @param groupCd {string} 해당 옵션이 없는 경우 null 입력
 * @returns {Promise}
 */
// 공통. statusCd group 호출
export function getStatusCd(type, code, groupCd) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/common/codes?groupCode=${type}&parentCode=${code}&parentGroupCode=${groupCd}`,
    accessToken
  );
}
// 공통. S3 - private 업로드 - cognitoId/token
export function getS3Auth() {
  const accessToken = getAuthorization();
  return Api.get("/api/common/cognito/tuneit-admin", accessToken);
}
// 공통. S3 - private - 사업자등록증 미리보기
export function getPrivateRedirect(fileId) {
  const accessToken = getAuthorization();
  return Api.get(`/api/common/private-file/${fileId}`, accessToken);
}

// 공통. 메뉴 조회(권한적용) => 메뉴 보여주는 (토큰로그인 후)
export function getRoleMenuAfterTokenLogin(token) {
  return Api.get("/api/menu-mgmt/role-menu", token);
}

export function getRoleMenu() {
  const accessToken = getAuthorization();
  return Api.get("/api/menu-mgmt/role-menu", accessToken);
}

// A0-1. 토큰 로그인
export function loginByToken() {
  const accessToken = getAuthorization();
  return Api.get("/api/sign-in/token", accessToken);
}
// A0-2. 로그아웃
export function logout() {
  const accessToken = getAuthorization();
  return Api.post("/api/sign-out", accessToken);
}

// A1-1. 파트너 로그인
export function signIn(body) {
  return Api.post("/api/partner/sign-in", null, body);
}
// A1-2. 관리자정보인증 확인
export function getAdminAuthInfo(adminNo, body, tempToken) {
  let url = `/api/partner/sign-in/member/${adminNo}/admin-confirm`;
  url = `${url}?${qs.stringify(body, { indices: false })}`;
  return requestByTempToken(
    "GET",
    process.env.REACT_APP_API_URL + url,
    tempToken
  );
}
// A1-3. 비밀번호 재설정(링크) 메일발송: 마스터 - 파트너 관리 - 최초 로그인 (운영 정보 관리 - 관리자 정보 - 비밀번호 재설정 메일 발송)
export function postEmailPwd(adminNo) {
  const accessToken = getAuthorization();
  return Api.post(
    `/api/partner/sign-in/member/${adminNo}/email-pwd`,
    accessToken
  );
}

// A2-1. 비밀번호 재설정 - 로그인 5회이상 실패 / 6개월 이상 경과
export function resetPassword(adminNo, passwd, tempToken) {
  let url = `/api/partner/sign-in/member/${adminNo}/pwd`;
  return requestByTempToken(
    "PATCH",
    process.env.REACT_APP_API_URL + url,
    tempToken,
    passwd
  );
}
// A2-2. 비밀번호 재설정 (메일 링크-인증토큰 x)
export function EmailResetPassword(body) {
  return Api.patch(`/api/partner/sign-in/member/email-pwd`, null, body);
}
